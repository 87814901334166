import * as React from 'react'
import { useState, useEffect } from 'react'
import QRCode from 'react-qr-code';
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'
import { useSelector } from 'react-redux'
import Spinner from '@/utils/Spinner'
import Notification from '@/components/user/Notification'

export const QRCodeForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [mfaData, setMfaData] = useState();
	const {
		id,
		primary,
		mfaData: {
			url
		}
	} = useSelector(state => state.user);

	useEffect(() => {
		const fetchMfaData = async () => {
			setIsLoading(true);
			try {
				const response = await fetchApi(restAPI.SET_MFA(id), {
					method: 'POST',
				});

				if (response?.error) {
					const preparedErrors = JSON.parse(response?.error)
					setError(preparedErrors?.message)
					throw new Error(response.error);
				}

				setMfaData(response.url);
			} catch (err) {
				console.log(err?.message)
			} finally {
				setIsLoading(false);
			}
		};

		if (!mfaData && !url) {
			fetchMfaData();
		}
	}, []);

	if (isLoading || !mfaData && !url) {
		return (
			<div className='m-md flex justify-center'>
				<Spinner
					className="mb-xl"
					style={{ width: 80, height: 80 }}
				/>
			</div>
		)
	}

	return (
		<div className='mt-xl mb-2xl'>
			{error ? (
				<Notification
					data-testid="form-errors"
					level="error"
					className="mb-lg lg:mb-xl"
				>
					{error}
				</Notification>
			) : <QRCode value={mfaData || url} bgColor="#FFFFFF" fgColor={primary} />}
		</div>
	)
}

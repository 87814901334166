import * as React from 'react'
import Layout from '@/container/Layout'
import Text from '@/utils/Text'
import Button from '@/components/buttons/Button'
import * as m from '@/utils/messages'
import { QRCodeForm } from '@/components/user/TwoFAQRSidebar/QRCodeForm'
import { useSelector } from 'react-redux'
import Avatar from '@/components/user/Avatar'
import useMediaQuery from '@/hooks/useMediaQuery'
import { unconfirmedSupportNumbers } from '@/utils/unconfirmedSupportNumbers'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import FormProgress from '@/components/widgets/FormProgress'

const paths = [
	{ view: 'Verify', text: 'Verify' },
	{ link: 'Password', text: 'Password' },
	{ link: '2FA', text: '2FA' },
	{ link: 'Terms', text: 'Terms' },
]

function TwoFASetupQRForm({ location }) {
	const {
		benefitBrandName,
		supportEmail,
		supportNumber,
		avatar
	} = useSelector(state => state.user)

	const isBigScreen = useMediaQuery('(min-width: 1023px)')
	const isFromActivation = location?.state?.isFromActivation

	const handleNextStep = () => {
		navigate('/two-fa-code-setup', { state: { 'isFirst': true, isFromActivation } })
	}

	return (
		<Layout className="flex-grow pt-lg lg:pt-2xl overflow-y-scroll">
			<Helmet>
				<title>{`Login | ${benefitBrandName}`}</title>
			</Helmet>
			<div
				className="w-full lg:h-60vh lg:flex lg:flex-row mx-auto pb-lg max-w-login lg:max-w-none"
			>
				<div className="lg:w-1/2 lg:px-xl">
					{avatar && (
						<Avatar
							image={avatar}
							className={'w-wilb-mob md:w-wilb h-auto mb-xl'}
						/>
					)}
					<Text
						as="h1"
						className="w-full leading-tight font-h-light text-mob-2xl md:text-2xl mb-lg"
					>
						{m.WELCOME_MESSAGE(benefitBrandName)}
					</Text>
					{
						isBigScreen && (
							<div className='h-full flex items-center'>
								<Text
									as="div"
									className="w-full text-mob-sm"
									dangerouslySetInnerHTML={{
										__html: (unconfirmedSupportNumbers.includes(supportNumber) ?
											m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) :	m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
									}}
								/>
							</div>
						)
					}
				</div>
				<div className={`${isBigScreen ? '' : 'mt-2xl'} mb-xl lg:mb-0 lg:w-6/10 lg:px-2xl flex flex-col`}>
					{
						isFromActivation ?
							<FormProgress
								progress={3}
								page={3}
								labels={paths}
								className="mb-xl md:mb-2xl"
								isFromActivation={isFromActivation}
							/> : null
					}
					<Text as="h2" className="text-mob-xl md:text-xl mb-xl lg:mb-xl">
						Enable two factor authentication
					</Text>
					<Text className="text-mob-md md:text-md mb-2xl">{m.TWO_FA_ENFORCE_1} </Text>
					<Text className="text-mob-md md:text-md mb-xl">{m.TWO_FA_ENFORCE_2} </Text>
					<QRCodeForm />
					<div className="md:flex md:items-center max-w-input md:mb-xl md:pb-xl">
						<div className="mb-lg md:mb-0 md:mr-xl">
							<Button
								data-testid="confirm-opt-out-button"
								as="button"
								type="button"
								className="w-full md:w-auto md:px-2xl"
								onClick={handleNextStep}
							>
								Enter the code
							</Button>
						</div>
						<div>
							<Text
								as="button"
								type="button"
								inline
								className="w-full text-center text-primary mr-lg md:w-auto"
								onClick={() => navigate('/')}
							>
								Close
							</Text>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default TwoFASetupQRForm

import * as React from 'react'
import TwoFASetupQRForm from '@/templates/TwoFASetupQRForm'
import { Location } from '@reach/router';

function TwoFAQRSetup() {
	return (
		<Location>
			{({ location }) => {
				return <TwoFASetupQRForm location={location} />
			}}
		</Location>
	)
}

export default TwoFAQRSetup
